<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>业绩目标分析</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <div class="clearfix table-tools">
                <div class="buttons">
                </div>
                <div class="search">
                <a-form layout='inline'>
                    <a-form-item>
                        <a-select :value="year" @change="handleChange">
                            <a-select-option v-for="(d, index) of yearList" :key="index" :value="d">{{ d }}</a-select-option>
                        </a-select>
                    </a-form-item>
                    
                    <a-form-item>
                        <a-select style="width: 160px" placeholder="选择员工" showSearch :filterOption="filterOption" @search="handleSearch" allowClear v-model="searchParams.search.obj_id">
                          <a-select-option v-for="(item,index) in staffList" :key="index"  :value="item.employee_id">{{item.filter_name}}</a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item>
                    <a-button @click="toScreen" :loading="loading" type="primary">筛选</a-button>
                    </a-form-item>
                </a-form>
                </div>
            </div>   
            <div class="dashboard bk-group" style="padding-bottom:1px">
                <statistics :count='counts' :col="6"/>
                <a-row class="mb16">
                    <a-col :lg="24" :xl="24">
                        <goal :loading='loading'  :item ='leadsData' :year='year' :showLineList="showLineList" />
                    </a-col>
                </a-row>

                <div class="table" style="margin-bottom:15px;background:#fff">
                    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                    <a-table size="small" :pagination="false" :bordered='false' rowKey="month"
                        :columns="columns" :dataSource="list">
                    </a-table>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
const columns = [
  { title: '时间', dataIndex: 'month', key: '1' ,align:'right'},
  { title: '目标金额(元)', dataIndex: 'achiement', key: '2',align:'right' },
  { title: '合同金额(元)', dataIndex: 'receivables', key: '3',align:'right' },
  { title: '完成度%', dataIndex: 'rate', key: '4' ,align:'right'},
]


import goal from './goal'
import moment from "moment"
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import statistics from '@/views/bi/ranking/money/statistics'
export default {
  name: 'follow',
  components:{
    goal,
    DatePicker,
    statistics
  },
  data() {
    return {
      loading:false,
      columns,
      leadsData:{columns:['时间','目标金额','合同金额','完成度'],rows:[]},
      studioLeadsData:{},
      list:[],
      year: '',
      counts:[],
      staffList:[],
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      yearList:[],
      showLineList:['合同金额','完成度']
    }
  },

  created() {
    let defaultYear = 2017
    let nowYear = Number(moment().format('YYYY'))
    let yearList = [nowYear]
    for(let i=0; i <= nowYear-defaultYear; i++){
        nowYear-=1
        yearList.push(nowYear)
    }
    this.yearList = yearList
    this.year = Number(moment().format('YYYY'))

    this.getList()
    this.getStaff()
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getList(obj){
      this.loading = true
      let res = await this.$store.dispatch('analysisGoalAction', obj)
      this.list = res.data.achiement
      let row = []
      if(this.list.length > 0){
          this.list.forEach(item=>{
              let obj = {
                  时间:item.month,
                  目标金额:item.achiement,
                  合同金额:item.receivables,
                  完成度:item.rate/100
              }
              row.push(obj)
          })
      }
      this.leadsData.rows = row
      this.loading = false
    },
    async getStaff(val){
      let obj = {q:val}
      let res = await this.$store.dispatch('searchAccountEmployeeAction', {params:obj})
      this.staffList = res.data;
    },
    notBeforeYear(date){
      const today = new Date();
      return date >= today;
    },
    handleSearch(val){
      this.getStaff(val)
    },
    handleChange(e){
        this.year = e
        this.searchParams.search.year = e
        // let time = `${e}-${moment().format('M-D')}`
        // this.endDate = time
        // this.searchParams.search['end_date'] = moment(time).endOf('year').format('YYYY-M-D')
        // this.searchParams.search['start_date'] = moment(time).startOf('year').format('YYYY-M-D')
    },
    toScreen(){
      let obj = {
        search:this.searchParams.search
      }
      this.getList(obj);
    }
  }
}
</script>